import React, { Component } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import * as util from 'src/styles/util'
import { colors, typography, animations } from 'src/styles'
import { buttonThemes as themes } from 'src/styles/themes'
import MaterialIcon from 'src/components/MaterialIcon'
import Spinner from 'react-spinner-material'
import { MdCheck, MdClose } from 'react-icons/md'
import { baseBorderRadius, uiElementSizes } from 'src/styles/globals'
import Link from 'src/components/Link'
import ButtonShape1 from 'src/assets/images/buttonShapes/button-shape-01.svg'
import ButtonShape2 from 'src/assets/images/buttonShapes/button-shape-02.svg'
import ButtonShape3 from 'src/assets/images/buttonShapes/button-shape-03.svg'
import ButtonShape4 from 'src/assets/images/buttonShapes/button-shape-04.svg'
import ButtonShape5 from 'src/assets/images/buttonShapes/button-shape-05.svg'
import ButtonShape6 from 'src/assets/images/buttonShapes/button-shape-06.svg'
import { MorphReplace } from 'react-svg-morph'
import { linear } from 'react-svg-morph/lib/utils/easing'
import { keyframes } from '@emotion/react'

export const morphOffset = keyframes`
	0%, 100% {
  }
  50% {
    transform: scaleX(1.15) scaleY(1.21);
  }
`

const ButtonBackgrounds = styled.div`
	${ ({ disabled }) => !disabled ? `
		animation: ${ morphOffset } ${ ({ duration }) => duration }ms cubic-bezier(0.370, 0.005, 0.600, 1.005) infinite;
	` : '' }
`

const buttonSettings = {
	radius: baseBorderRadius,
	border: 'none',
	transitionSpeed: animations.mediumSpeed,
	verticalOffset: '1px'
}

const animationDurations = [3200, 3000, 2800]

const getState = (loading, error, success, disabled) => {
	let buttonState = ''
	if (error) {
		buttonState = 'error'
	} else if (loading === 'true') {
		buttonState = 'loading'
	} else if (success) {
		buttonState = 'success'
	} else if (disabled) {
		buttonState = 'disabled'
	}

	return buttonState
}

const ButtonBgShape = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: transform ${ buttonSettings.transitionSpeed } ease-in-out;
	svg {
		overflow: visible;
		width: 100%;
		height: 100%;
		transition: filter ${ buttonSettings.transitionSpeed } ease-in-out;
		path {
			transition: fill ${ buttonSettings.transitionSpeed } ease-in-out, d 100ms ease-in-out;
		}
	}
`

const setButtonTheme = (theme, state) => `
	color: ${ themes[theme].color };
	background: transparent;
	${ ButtonBgShape } {
		svg path {
			fill: ${ themes[theme].background };
		}
	}
	${ theme.shadow ? `
		box-shadow: ${ theme.shadow };
	` : `
		box-shadow: none;
	` }
	${ themes[theme].borderColor ? `
		border-color: ${ themes[theme].borderColor };
	` : `
		border-color: ${ themes[theme].background };
	` }
	&:hover {
		${ !state ? `
			color: ${ themes[theme].hoverColor };
			background: transparent;
			${ ButtonBgShape } {
				svg {
					path {
						// animation-play-state: running;
						fill: ${ themes[theme].hoverBackground };
					}
				}
			}
			${ themes[theme].borderHoverColor ? `
				border-color: ${ themes[theme].borderHoverColor };
			` : `
				border-color: ${ themes[theme].hoverBackground };
			` }
			${ theme.hoverShadow ? `
				box-shadow: ${ theme.hoverShadow };
			` : '' }
		` : '' }
	}
`

const DisabledButtonStyles = () => `
	&[disabled],
	&:disabled {
		opacity: .5;
		// background: ${ colors.transparent };
		// border-color: ${ colors.textColor };
		// color: ${ rgba(colors.bgColor, 0.6) };
		cursor: not-allowed;
	}
`

const ButtonIcon = styled.div`
	${ ({ position }) => position === 'left' ? `
		margin-right: .5em;
	` : `
		margin-left: .5em;
	` }
	span,
	svg {
		display: block;
	}
`

const buttonPaddingIncrement = {
	normal: 0.666,
	wide: 0.666,
	extraWide: 0.666
}

const ButtonStyles = (state, shape, size, theme, buttonWidth) => (`
	position: relative;
	appearance: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-touch-callout: none;
	outline: none;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	border: ${ buttonSettings.border };
	height: ${ uiElementSizes[size] };
	${ util.responsiveStyles('height', uiElementSizes[size] * 1.1, uiElementSizes[size], uiElementSizes[size], uiElementSizes[size]) }
	padding: 0 calc(${ uiElementSizes[size] }px * ${ buttonPaddingIncrement[buttonWidth] }) ${ buttonSettings.verticalOffset };
	min-width: calc(${ uiElementSizes[size] }px * 2.5);
	text-transform: none;
	letter-spacing: 0;
	// border-radius: ${ buttonSettings.radius }px;
	border-radius: 50%;
	font-size: 14px;
	text-align: center;
	${ typography.buttonStyle }
	line-height: 1em;
	${ util.fontSmoothing }
	transition: background ${ buttonSettings.transitionSpeed } ease-in-out,
							color ${ buttonSettings.transitionSpeed } ease-in-out,
							border ${ buttonSettings.transitionSpeed } ease-in-out,
							box-shadow ${ buttonSettings.transitionSpeed } ease-in-out,
							transform ${ buttonSettings.transitionSpeed } ease-in-out,
							opacity ${ buttonSettings.transitionSpeed } ease-in-out;
	// Button States
	${ state === 'loading' ? 'cursor: wait; pointer-events: none;' : '' }
	${ state === 'error' || state === 'success' ? 'cursor: default; pointer-events: none;' : '' }

	// Button Shapes
	${ shape ? `
		${ shape.includes('circle') || shape.includes('square') ? `
			padding: 0 !important;
			${ util.responsiveStyles('width', uiElementSizes[size] * 1.1, uiElementSizes[size], uiElementSizes[size], uiElementSizes[size]) }
			${ util.responsiveStyles('min-width', uiElementSizes[size] * 1.1, uiElementSizes[size], uiElementSizes[size], uiElementSizes[size]) }
			${ ButtonIcon } {
				margin: 0;
			}
		` : '' }
	` : '' }
	${ shape && shape.includes('circle') ? 'border-radius: 50%;' : '' }

	// Button Themes
	${ setButtonTheme(theme, state) }
	${ state === 'disabled' ? `${ DisabledButtonStyles() }` : '' }

	// Button Size Tweaks
	${ size === 'small' ? `
		font-size: inherit;
	` : '' }
	${ size === 'tiny' ? `
		font-size: inherit;
		padding: 0 calc(${ uiElementSizes[size] }px * .75) ${ buttonSettings.verticalOffset };
	` : '' }

`)

const ButtonContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: relative;
	z-index: 2;
	svg {
		* {
			fill: currentcolor;
		}
	}
`

const StyledButtonLink = styled(Link)`
	${ ({ loading, error, success, disabled, shape, size, theme, buttonWidth }) => ButtonStyles(getState(loading, error, success, disabled), shape, size, theme, buttonWidth) }
`

const StyledButtonElement = styled.div`
	${ ({ loading, error, success, disabled, shape, size, theme, buttonWidth }) => ButtonStyles(getState(loading, error, success, disabled), shape, size, theme, buttonWidth) }
`

const shapes = [
	<ButtonShape1 key='shape1' />,
	<ButtonShape2 key='shape2' />,
	<ButtonShape3 key='shape3' />,
	<ButtonShape4 key='shape4' />,
	<ButtonShape5 key='shape5' />,
	<ButtonShape6 key='shape6' />,
	// <ButtonShape7 key='shape7' />
]

class Button extends Component {
	state = {
		buttonShapeIndex: this.props.stagger || 0
	}

	componentDidMount = () => {
		this.setState({ buttonShapeIndex: this.props.stagger + 1 })
		const timeout = animationDurations[this.props.stagger]
		this.shapeInterval = setInterval(() => {
			const shapeIndex = this.state.buttonShapeIndex
			if (shapeIndex === shapes.length - 1) {
				this.setState({ buttonShapeIndex: 0 })
			} else {
				this.setState({ buttonShapeIndex: shapeIndex + 1 })
			}
		}, timeout)
	}

	componentWillUnmount = () => {
		clearInterval(this.shapeInterval)
	}

	renderIcon = (icon, position, shape, size) => {
		let renderedIcon = false
		if (typeof icon === 'string') {
			renderedIcon = <ButtonIcon size={size} position={position} shape={shape}><MaterialIcon size={this.props.size === 'tiny' ? '18px' : '24px'}>{icon}</MaterialIcon></ButtonIcon>
		} else {
			renderedIcon = <ButtonIcon size={size} position={position} shape={shape}>{icon}</ButtonIcon>
		}
		return renderedIcon
	}

	renderButtonContent = () => {
		const { loading, error, success, children, label, icon, iconPosition, shape, size } = this.props
		if (loading) {
			return <ButtonContent>
				<Spinner radius={18} color='inherit' stroke={2} />
			</ButtonContent>
		} else if (error) {
			return <ButtonContent>
				<MdClose size={'1.5em'} />
			</ButtonContent>
		} else if (success) {
			return <ButtonContent>
				<MdCheck size={'1.5em'} />
			</ButtonContent>
		} else {
			return <ButtonContent>
				{icon && iconPosition !== 'right' ? this.renderIcon(icon, iconPosition, shape, size) : false}
				{children || label}
				{icon && iconPosition === 'right' ? this.renderIcon(icon, iconPosition, shape, size) : false}
			</ButtonContent>
		}
	}

	renderButtonBackground = (timeout, disabled) => {
		return (
			<ButtonBackgrounds as={!disabled ? MorphReplace : 'div'} disabled={disabled} preserveAspectRatio="none" duration={timeout} rotation='none' easing={linear} viewBox="0 0 177.89 78.25">
				{!disabled ? shapes[this.state.buttonShapeIndex] : shapes[1]}
			</ButtonBackgrounds>
		)
	}

	render () {
		const {
			to,
			external,
			target,
			icon,
			iconPosition,
			loading,
			error,
			success,
			disabled,
			onClick,
			setTheme,
			className,
			shape,
			size,
			title,
			name,
			stagger,
			as
		} = this.props

		let buttonWidth = 'normal'
		if (this.props.children?.length > 14) {
			buttonWidth = 'extraWide'
		} else if (this.props.children?.length > 10) {
			buttonWidth = 'wide'
		}

		if (to) {
			return (
				<StyledButtonLink
					className={'button ' + className}
					to={to}
					target={target}
					external={external}
					icon={icon}
					iconPosition={iconPosition}
					loading={loading ? loading.toString() : 'false'}
					error={error}
					success={success}
					disabled={disabled}
					onClick={onClick}
					theme={setTheme || 'default'}
					shape={shape}
					size={size}
					title={title}
					name={name || title}
					aria-label={name || title}
					rel={external ? 'noopener noreferrer' : ''}
					buttonWidth={buttonWidth}
				>
					{setTheme !== 'transparent' && (
						<ButtonBgShape>
							{this.renderButtonBackground(animationDurations[stagger], disabled)}
						</ButtonBgShape>
					)}
					{this.renderButtonContent()}
				</StyledButtonLink>
			)
		} else {
			return (
				<StyledButtonElement
					className={'button ' + className}
					icon={icon}
					iconPosition={iconPosition}
					loading={loading ? loading.toString() : 'false'}
					error={error}
					success={success}
					disabled={disabled}
					onClick={onClick}
					theme={setTheme || 'default'}
					shape={shape}
					size={size}
					title={title}
					name={name || title}
					aria-label={name || title}
					buttonWidth={buttonWidth}
					as={as || 'button'}
				>
					{setTheme !== 'transparent' && (
						<ButtonBgShape>
							{this.renderButtonBackground(animationDurations[stagger], disabled)}
						</ButtonBgShape>
					)}
					{this.renderButtonContent()}
				</StyledButtonElement>
			)
		}
	}
}

Button.defaultProps = {
	setTheme: 'default',
	size: 'medium',
	shape: 'default',
	iconPosition: 'left',
	stagger: 0
}

export default Button
