export const margin = 100 / 14 + 'vw'
export const gutter = '3vw'
export const containerSmall = '1 [12] 1'
export const containerMedium = containerSmall
export const containerLarge = containerSmall
export const containerLarger = containerSmall

export const containerLargeMargins = '5vw'
export const containerMediumMargins = '5vw'
export const containerSmallMargins = '20px'

const colSpacingSmall = '0'
const colSpacingMedium = '0'
const colSpacingLarge = '0'

const rowSpacingSmall = colSpacingSmall
const rowSpacingMedium = colSpacingMedium
const rowSpacingLarge = colSpacingLarge

const gridSettings = {
	colGap: [colSpacingSmall, colSpacingMedium, colSpacingLarge],
	rowGap: [rowSpacingSmall, rowSpacingMedium, rowSpacingLarge],
	vAlign: 'center',
}

export default gridSettings
