// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-beverage-product-template-jsx": () => import("./../../../src/templates/BeverageProductTemplate.jsx" /* webpackChunkName: "component---src-templates-beverage-product-template-jsx" */),
  "component---src-templates-cocktail-template-jsx": () => import("./../../../src/templates/CocktailTemplate.jsx" /* webpackChunkName: "component---src-templates-cocktail-template-jsx" */),
  "component---src-templates-merch-template-jsx": () => import("./../../../src/templates/MerchTemplate.jsx" /* webpackChunkName: "component---src-templates-merch-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */)
}

