import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import { colors } from 'src/styles'
import { OverlayContext } from 'src/state/OverlayState'
import { Transition } from 'react-transition-group'
import RoughEdgeSvg from 'src/assets/images/rough-edge-box.svg'

const timeout = 300
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 6;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	padding: 4%;

	transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
	// transform: translate3d(110%, 0, 0);
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		// transform: translate3d(110%, 0, 0);
		opacity: 0;
	` : '' }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		// transform: none;
		opacity: 1;
	` : '' }
`

const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${ colors.bgColor };
	opacity: .8;
	z-index: 1;
`

const ModalBox = styled.div`
	position: relative;
	margin: auto;
	z-index: 2;
`

const CloseButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	color: ${ colors.hrColor };
	&:hover {
		color: ${ colors.pink5 };
	}
`

const RoughEdge = styled(RoughEdgeSvg)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	* {
		fill: ${ colors.white };
	}
`

const Modal = ({ children, className, open, closable = true, id, closeable, noRoughEdge }) => {
	const { toggleModal, modal } = useContext(OverlayContext)
	return (
		<Transition
      in={open || id === modal}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
      mountOnEnter
    >
      {transitionStatus => (
				<ModalWrapper setTheme="lightGrey" className={className} transitionStatus={transitionStatus}>
					<Overlay onClick={() => { if (closeable) { toggleModal(false) } } }/>
					<ModalBox>
						<div style={{ position: 'relative', zIndex: 1 }}>
						{children}
						</div>
						{closable && (
							<CloseButton icon='close' shape='circle' onClick={() => toggleModal(false)} size='small' setTheme='transparent' />
						)}
						{!noRoughEdge && (
							<RoughEdge/>
						)}
					</ModalBox>
				</ModalWrapper>
			)}
    </Transition>
	)
}

export default Modal
