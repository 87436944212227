import * as colors from './colors'
import { lighten, darken } from 'polished'

// Themes (ThemeSelector Component)
export const themes = {
	default: {
		color: colors.textColor,
		background: colors.bgColor,
		hoverColor: colors.mainColor,
		buttonTheme: 'pink',
		buttonThemeSecondary: 'white'
	},
	pink: {
		color: colors.white,
		background: colors.pink4,
		hoverColor: colors.blush,
		buttonTheme: 'white',
		buttonThemeSecondary: 'white'
	},
	yellow: {
		color: colors.bgColor,
		background: colors.yellow,
		hoverColor: colors.mainColor,
		buttonTheme: 'pink',
		buttonThemeSecondary: 'white'
	},
	honey: {
		color: colors.white,
		background: colors.honey,
		hoverColor: colors.blush,
		buttonTheme: 'white',
		buttonThemeSecondary: 'white'
	},
	orange: {
		color: colors.white,
		background: colors.orange,
		hoverColor: colors.blush,
		buttonTheme: 'white',
		buttonThemeSecondary: 'white'
	},
	lavender: {
		color: colors.white,
		background: colors.lavender,
		hoverColor: colors.blush,
		buttonTheme: 'white',
		buttonThemeSecondary: 'white'
	},
	white: {
		color: colors.mainColor,
		background: colors.white,
		hoverColor: colors.mainColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'blush'
	},
	midPink: {
		color: colors.textColor,
		background: colors.pink2,
		hoverColor: colors.textColor,
		buttonTheme: 'default',
		buttonThemeSecondary: 'white'
	},
	darkPink: {
		color: colors.white,
		background: colors.pink5,
		hoverColor: colors.blush,
		buttonTheme: 'white',
		buttonThemeSecondary: 'white'
	},
	bgColor: {
		color: colors.textColor,
		background: colors.pink4,
		hoverColor: colors.mainColor,
		buttonTheme: 'pink',
		buttonThemeSecondary: 'white'
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		buttonTheme: 'white',
		buttonThemeSecondary: 'lightGrey'
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		buttonTheme: 'pink',
		buttonThemeSecondary: 'white'
	}
}

// Button Themes
export const buttonThemes = {
	pink: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.white,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	default: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	currentcolor: {
		color: colors.textColor,
		background: 'currentcolor',
		hoverColor: 'currentcolor',
		hoverBackground: 'currentcolor'
	},
	currentcolorOutlined: {
		color: 'currentcolor',
		background: 'transparent',
		hoverColor: colors.textColor,
		hoverBackground: 'currentcolor',
		borderColor: 'currentcolor'
	},
	black: {
		color: colors.bgColor,
		background: colors.black,
		hoverColor: colors.mainColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	white: {
		color: colors.pink5,
		background: colors.white,
		hoverColor: colors.mainColor,
		hoverBackground: colors.white
	},
	transparent: {
		color: colors.black,
		background: colors.transparent,
		hoverColor: colors.pink5,
		hoverBackground: colors.white
	},
	transparentLight: {
		color: colors.bgColor,
		background: colors.transparent,
		hoverColor: colors.white,
		hoverBackground: colors.transparent
	},
	lightGrey: {
		color: colors.textColor,
		background: colors.lightGrey,
		hoverColor: colors.textColor,
		hoverBackground: darken(0.07, colors.lightGrey)
	},
	mainColor: {
		color: colors.bgColor,
		background: colors.mainColor,
		hoverColor: colors.bgColor,
		hoverBackground: darken(0.07, colors.mainColor)
	},
	textColor: {
		color: colors.bgColor,
		background: colors.textColor,
		hoverColor: colors.bgColor,
		hoverBackground: lighten(0.2, colors.textColor)
	}
}

// Input Themes
export const inputThemes = {
	default: {
		color: colors.textColor,
		placeholderColor: colors.mainColor,
		background: colors.white,
		accentColor: colors.pink5,
		hoverColor: colors.pink5,
		borderColor: 'transparent',
		hoverBorderColor: 'transparent',
		focusBorderColor: 'transparent',
		hoverBackground: colors.white,
		focusBackground: colors.white,
	},
	light: {
		color: colors.textColor,
		placeholderColor: colors.pink5,
		background: colors.bgColor,
		accentColor: colors.pink5,
		hoverColor: colors.pink5,
		borderColor: 'transparent',
		hoverBorderColor: 'transparent',
		focusBorderColor: 'transparent',
		hoverBackground: colors.white,
		focusBackground: colors.white,
	}
}

export default themes
