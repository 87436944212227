import { lighten, darken } from 'polished'

export const black = '#010202'
export const white = '#fff'

// Site Specific Colors
export const yellow = '#DC9B32'

export const blush = '#F2EBE7'
export const pink1 = '#ECDED6'
export const pink2 = '#EDD8D0'
export const pink3 = '#DABAAB'
export const pink4 = '#E1887C'
export const pink5 = '#C36251'

export const lavender = '#BEBAD1'
export const honey = '#EDC28C'
export const beige = '#B0825B'
export const nude = '#D5AE8D'
export const orange = '#D58128'
export const darkGrey = '#191919'

export const lightGreen = '#74A460'
export const darkGreen = '#1F5708'

// Basic Colors
export const transparent = 'transparent'
export const currentcolor = 'currentcolor'
export const bgColor = blush
export const mainColor = pink4
export const alert = pink5
export const notify = yellow
export const success = lightGreen
export const textColor = darkGrey
export const lightTextColor = beige
export const lightGrey = pink1
export const hrColor = pink3

// Color Variations
export const mainColorDarken = darken(0.07, mainColor)
export const mainColorLighten = lighten(0.07, mainColor)
