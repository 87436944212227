import React, { useContext, useState, useEffect } from 'react'
import { OverlayContext } from 'src/state/OverlayState'
import { ShopifyContext } from 'src/state/ShopifyState'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import Select from 'src/components/Select'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import { locations } from 'src/utils/locations'
import { util } from 'src/styles'
import NewsletterForm from 'src/components/NewsletterForm'
import Helmet from 'react-helmet'

const Wrapper = styled.div`
	text-align: center;
	max-width: 90vw;
	${ util.responsiveStyles('width', 800, 600, 600, 600) }
	${ util.responsiveStyles('padding', 90, 60, 50, 30) }
	${ util.responsiveStyles('padding-top', 90, 70, 50, 45) }
	${ util.responsiveStyles('padding-bottom', 100, 80, 60, 50) }
	clip-path: url(#raggedThumb2);
`

const ShippingZoneModal = () => {
	const [shippingAvailable, setShippingAvailable] = useState()
	const [shippingLocation, setShippingLocation] = useState()
	const { shopifyAdmin } = useStaticQuery(
		graphql`
			query {
				shopifyAdmin {
					deliveryProfiles(first: 10) {
						edges {
							node {
								profileLocationGroups {
									locationGroupZones(first: 10) {
										edges {
											node {
												zone {
													countries {
														provinces {
															value: code
															label: name
														}
													}
												}
											}
										}
									}
								}
								profileItems(first: 10) {
									edges {
										node {
											product {
												id
												handle
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	)

	const { addVariantToCart, store: { adding } } = useContext(ShopifyContext)
	const { toggleCart, toggleModal, currentVariant, currentProduct } = useContext(OverlayContext)

	const { deliveryProfiles } = shopifyAdmin

	const locationGroupZoneProvincesForProduct = deliveryProfiles.edges.reduce((acc, { node: { profileLocationGroups, profileItems } }) => {
			const productInProfile = profileItems.edges.some(({ node: { product } }) => product.handle === currentProduct.handle)
			if (productInProfile) {
				const locationGroupZoneProvinces = profileLocationGroups.reduce((acc, { locationGroupZones: { edges: zones } }) => {
					const zoneProvinces = []
					zones.forEach(({ node: { zone: { countries } } }) => {
						countries.forEach(({ provinces }) => {
							zoneProvinces.push(...provinces)
						})
					})
					return [...acc, ...zoneProvinces]
				}, [])
				return [...acc, ...locationGroupZoneProvinces]
			}
			return acc
	}, [])

	const handleAddToCart = () => {
		addVariantToCart(currentVariant, 1)
		if (!adding) {
			toggleCart()
			toggleModal()
		}
	}

	const handleLocationSelect = event => {
		const selectedLocation = event.target.value
		if (selectedLocation) {
			setShippingLocation(selectedLocation)
		}
		if (locationGroupZoneProvincesForProduct.filter(zone => zone?.value === selectedLocation)?.length > 0) {
			setShippingAvailable(true)
		} else {
			if (shippingAvailable) {
				setShippingAvailable(false)
			}
		}
	}

	return (
		<>
			<Helmet
				meta={[
					{
						name: 'viewport',
						content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0',
					}
				]}
			/>
			<Wrapper>
				<Grid small='[1]' rowGap='24px'>
					{shippingLocation ? (
						<div>
							<h5 style={{ margin: '0' }}>
								{shippingAvailable ? "It's Availabile!" : 'Not Availabile'}
								{!shippingAvailable && (
									<div>
										<p style={{ margin: '1em 0 0 0' }}>Enter your email to find out when we are shipping to your area.</p>
									</div>
								)}
							</h5>
						</div>
					) : (
						<div>
							<h5 style={{ margin: '0' }}>Check Availability</h5>
						</div>
					)}
					<div>
						<Select
							label='Ship to'
							name='location'
							placeholder='Select State'
							options={locations}
							onChange={handleLocationSelect}
							theme='light'
						/>
					</div>
					{!shippingAvailable && !shippingLocation && (
						<div>
							<p className='tiny' style={{ margin: '0 auto', maxWidth: '24em' }}>Please select a state to check product shipping availability and proceed to checkout.</p>
						</div>
					)}
					{shippingAvailable ? (
						<>
							<div>
								<Button
									size='large'
									onClick={() => handleAddToCart()}
									loading={adding}
								>Add to cart</Button>
							</div>
						</>
					) : (
						shippingLocation && (
							<div>
								<NewsletterForm />
							</div>
						)
					)}
				</Grid>
			</Wrapper>
		</>
	)
}

export default ShippingZoneModal
