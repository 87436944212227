import React from 'react'
import moment from 'moment'

const initialState = {
  modal: false,
  mobileMenu: false,
  cartOpen: false,
  currentVariant: false,
  currentProduct: false,
  toggleModal: () => { },
  toggleMobileMenu: () => { },
  closeMobileMenu: () => { },
  toggleCart: () => { }
}

export const OverlayContext = React.createContext(initialState)

class OverlayState extends React.Component {
  constructor (props) {
    super(props)
    this.state = initialState
  }

  // componentDidMount () {
  //   document.addEventListener('keydown', this.escFunction, false)
  // }

  // componentWillUnmount () {
  //   document.removeEventListener('keydown', this.escFunction, false)
  // }

  // escFunction = e => {
  //   if (e && e.keyCode === 27) { // escape key maps to keycode `27`
  //     this.toggleCart()
  //   }
  // }

  toggleMobileMenu = () => {
    const { mobileMenu } = this.state
    if (!mobileMenu) this.setState({ mobileMenu: true })
    else this.setState({ mobileMenu: false })
  }

  closeMobileMenu = () => {
    this.setState({ mobileMenu: false })
  }

  toggleModal = id => {
    if (id) {
      this.setState({ modal: id })
    } else {
      this.setState({ modal: false })
    }
  }

  setCurrentVariant = id => {
    if (id) {
      this.setState({ currentVariant: id })
    } else {
      this.setState({ currentVariant: false })
    }
  }

  setCurrentProduct = id => {
    if (id) {
      this.setState({ currentProduct: id })
    } else {
      this.setState({ currentProduct: false })
    }
  }

  toggleCart = () => {
    const { cartOpen } = this.state
    if (!cartOpen) this.setState({ cartOpen: true })
    else this.setState({ cartOpen: false })
  }

  componentDidMount () {
    const legalAgeTimestamp = localStorage.getItem('legalAgeTimestamp')
    const legalAge = localStorage.getItem('legalAge')
    if (!legalAge || !legalAgeTimestamp || moment().isSameOrAfter(moment(legalAgeTimestamp).add(7, 'day'))) {
      localStorage.removeItem('legalAge')
      localStorage.removeItem('legalAgeTimestamp')
      this.toggleModal('ageGate')
    }
  }

  render () {
    const { children } = this.props
    return (
      <OverlayContext.Provider
        value={{
          ...this.state,
          toggleCart: this.toggleCart,
          toggleMobileMenu: this.toggleMobileMenu,
          closeMobileMenu: this.closeMobileMenu,
          toggleModal: this.toggleModal,
          setCurrentVariant: this.setCurrentVariant,
          setCurrentProduct: this.setCurrentProduct
        }}
      >
        {children}
      </OverlayContext.Provider>
    )
  }
}

export default OverlayState
