import MaterialIconsWoff from '../assets/fonts/icons/material-icons-regular.woff'
import MaterialIconsWoff2 from '../assets/fonts/icons/material-icons-regular.woff2'

import MalloryWoff from '../assets/fonts/mallory/Mallory-Book.woff'
import MalloryWoff2 from '../assets/fonts/mallory/Mallory-Book.woff2'

import MalloryBoldWoff from '../assets/fonts/mallory/Mallory-Bold.woff'
import MalloryBoldWoff2 from '../assets/fonts/mallory/Mallory-Bold.woff2'

import MalloryMediumWoff from '../assets/fonts/mallory/Mallory-Medium.woff'
import MalloryMediumWoff2 from '../assets/fonts/mallory/Mallory-Medium.woff2'

import DadsRecipeWoff from '../assets/fonts/dadsrecipe/DadsRecipe.woff'
import DadsRecipeWoff2 from '../assets/fonts/dadsrecipe/DadsRecipe.woff2'

export const fontFace = (fontName, woff, woff2, fontWeight = 'normal', fontStyle = 'normal') => `
	@font-face {
		font-family: '${ fontName }';
		src:  url('${ woff }') format('woff'),
					url('${ woff2 }') format('woff2');
		font-weight: ${ fontWeight };
		font-style: ${ fontStyle };
		font-display: swap;
	}
`
export const MaterialIcons = 'Material Icons'
export const MaterialIconsFont = fontFace(MaterialIcons, MaterialIconsWoff, MaterialIconsWoff2)

export const Mallory = 'Mallory'
export const MalloryFont = fontFace(Mallory, MalloryWoff, MalloryWoff2)
export const MalloryBoldFont = fontFace(Mallory, MalloryBoldWoff, MalloryBoldWoff2, '800')
export const MalloryMediumFont = fontFace(Mallory, MalloryMediumWoff, MalloryMediumWoff2, '600')

export const DadsRecipe = 'Dads Recipe'
export const DadsRecipeFont = fontFace(DadsRecipe, DadsRecipeWoff, DadsRecipeWoff2)
