import React, { Component } from 'react'
import styled from '@emotion/styled'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import { colors, util } from 'src/styles'
import { validateEmail } from 'src/utils/validations'
import axios from 'axios'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const buttonSizes = {
	tiny: 32,
	small: 40,
	medium: 50,
	large: 60
}

const FormWrapper = styled.div`
	width: 100%;
	max-width: 700px;
`

const Form = styled.form`
	display: inline-block;
	vertical-align: top;
	// max-width: 600px;
	width: 100%;
	text-align: left;
	position: relative;
`

const StyledInput = styled(Input)`
	position: relative;
	z-index: 1;
	input {
		${ ({ size }) => util.responsiveStyles('padding-right', buttonSizes[size] * 1.3, buttonSizes[size], buttonSizes[size], buttonSizes[size]) }
	}
	width: 100%;
	${ ({ type }) => type === 'hidden' ? `
		display: none;
	` : '' }
	${ ({ isValid }) => isValid === false ? `
		input {
			border-color: ${ colors.white };
		}
	` : '' }
`

const SubmitButton = styled(Button)`
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	color: ${ colors.mainColor }
`

const StyledMessage = styled.p`
	margin: .75em 0 0 0;
	color: ${ colors.pink4 };
`

class NewsletterForm extends Component {
	constructor (props) {
		super(props)
		this.submitForm = this.submitForm.bind(this)
		this.state = {
			status: '',
			email: '',
			message: null
		}
	}

	handleInput = event => {
		this.setState({ [event.target.name]: event.target.value })
	}

	render () {
		const { className, theme = 'light', size = 'medium' } = this.props
		const { status, email } = this.state
		const valid = email !== '' && validateEmail(email)

		return (
			<FormWrapper className={className}>
				<Form
					onSubmit={this.submitForm}
				>
					<StyledInput type="hidden" name="_replyto" value={email} />

					<StyledInput
						size={size}
						setTheme={theme || 'default'}
						onChange={this.handleInput}
						type="email"
						name="email"
						label="Email"
						value={email}
						isValid={email !== '' ? validateEmail(email) : true}
					/>

					<SubmitButton size={size} type="submit" disabled={!valid} icon={status === 'success' ? 'check' : 'arrow_forward'} shape='simple square' setTheme='transparent' />
				</Form>
				{ this.state.message && (
					<StyledMessage>{this.state.message}</StyledMessage>
				)}
			</FormWrapper>
		)
	}

	submitForm (e) {
		e.preventDefault()

		const data = JSON.stringify({
			name: 'Add your name in the body'
		})

		const config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `https://api.flodesk.com/v1/subscribers?email=${ this.state.email }`,
			headers: {
				Authorization: `Basic ${ process.env.GATSBY_FLODESK_TOKEN }`,
				password: ' ',
				'Content-Type': 'application/json'
			},
			data: data
		}

		const segmentConfig = {
			method: 'post',
			maxBodyLength: Infinity,
			url: `https://api.flodesk.com/v1/subscribers/${ this.state.email }/segments?SegmentIDs=65ca82f1c5dd948177b24354`,
			headers: {
				Authorization: `Basic ${ process.env.GATSBY_FLODESK_TOKEN }`,
				password: ' ',
				'Content-Type': 'application/json'
			}
		}

		axios.request(config)
			.then(response => {
				if (response) {
					console.log('Thank you!')
					this.setState({ status: 'success', message: '' })
					axios.request(segmentConfig)
						.then(responseTwo => {
							if (responseTwo) {
								console.log('Added to segment!')
							}
						})
				}
			})
			.catch(error => {
				console.log(error)
			})
	}

	// submitForm (ev) {
	// 	ev.preventDefault()
	// 	addToMailchimp(this.state.email) // listFields are optional if you are only capturing the email address.
	// 	.then(data => {
	// 		// I recommend setting data to React state
	// 		// but you can do whatever you want (including ignoring this `then()` altogether)
	// 		this.setState({ status: data.result, message: data.msg })
	// 	})
	// 	.catch(() => {
	// 		// unnecessary because Mailchimp only ever
	// 		// returns a 200 status code
	// 		// see below for how to handle errors
	// 	})
	// }
}

export default NewsletterForm
